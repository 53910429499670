import React from 'react'

const OpenzaakLogo = (props) => (
  <svg viewBox="0 0 50 50" stroke-width="0.501" stroke-linejoin="bevel" fill-rule="evenodd" overflow="visible" width="50" height="50" {...props}>
   <g id="Document" fill="none" stroke="black" font-family="Times New Roman" font-size="16" transform="scale(.5 -.5)">
    <g id="Spread" transform="translate(0 -150)">
     <g id="Layer 1">
      <path d="M 43.841,148.021 C 72.593,176.773 119.269,176.773 148.021,148.021 C 176.773,119.269 176.773,72.593 148.021,43.841 C 119.269,15.089 72.593,15.089 43.841,43.841 C 15.089,72.593 15.089,119.269 43.841,148.021 Z" fill="#ffffff" stroke-width="7.054" stroke="#178be9" stroke-linejoin="round" stroke-linecap="round" marker-start="none" marker-end="none" stroke-miterlimit="79.8403193612775"/>
      <path d="M 52.756,52.756 C 45.919,59.591 41.043,67.648 38.129,76.228 L 45.704,76.228 C 48.333,69.507 52.361,63.211 57.787,57.787 C 78.84,36.733 113.021,36.733 134.074,57.787 C 139.498,63.211 143.526,69.507 146.155,76.228 L 153.73,76.228 C 150.816,67.648 145.94,59.591 139.105,52.756 C 115.273,28.922 76.588,28.922 52.756,52.756 Z" stroke-width="0.882" stroke="none" fill="#178be9" fill-rule="evenodd" marker-start="none" marker-end="none" stroke-miterlimit="79.8403193612775"/>
      <path d="M 64.404,64.404 C 60.852,67.953 58.025,71.957 55.923,76.228 L 63.979,76.228 C 65.479,73.796 67.283,71.5 69.393,69.392 C 84.04,54.745 107.821,54.745 122.468,69.392 C 124.576,71.5 126.38,73.796 127.88,76.228 L 135.936,76.228 C 133.834,71.957 131.007,67.953 127.457,64.404 C 110.055,47.001 81.806,47.001 64.404,64.404 Z" stroke-width="0.882" stroke="none" fill="#178be9" fill-rule="evenodd" marker-start="none" marker-end="none" stroke-miterlimit="79.8403193612775"/>
      <g id="Group" fill-rule="nonzero" stroke-linejoin="round" stroke-linecap="round" stroke="none" stroke-width="0.591" fill="#000000" stroke-miterlimit="79.8403193612775">
       <g id="Group_1">
        <path d="M 49.964,126.396 C 49.964,124.308 49.429,122.679 48.358,121.508 C 47.288,120.338 45.797,119.752 43.887,119.752 C 42.693,119.752 41.638,120.022 40.721,120.561 C 39.805,121.1 39.1,121.874 38.607,122.883 C 38.114,123.892 37.867,125.063 37.867,126.396 C 37.867,128.468 38.399,130.085 39.462,131.248 C 40.525,132.412 42.023,132.993 43.956,132.993 C 45.805,132.993 47.268,132.398 48.347,131.208 C 49.425,130.018 49.964,128.414 49.964,126.396 Z M 40.663,126.396 C 40.663,123.445 41.754,121.971 43.933,121.971 C 46.09,121.971 47.168,123.445 47.168,126.396 C 47.168,129.315 46.082,130.775 43.91,130.775 C 42.77,130.775 41.944,130.397 41.432,129.642 C 40.92,128.887 40.663,127.805 40.663,126.396 Z" marker-start="none" marker-end="none"/>
        <path d="M 59.566,119.752 C 57.948,119.752 56.693,120.334 55.799,121.497 L 55.637,121.497 C 55.745,120.418 55.799,119.764 55.799,119.533 L 55.799,114.299 L 53.084,114.299 L 53.084,132.762 L 55.279,132.762 C 55.341,132.523 55.468,131.953 55.66,131.052 L 55.799,131.052 C 56.646,132.346 57.917,132.993 59.612,132.993 C 61.206,132.993 62.448,132.415 63.338,131.26 C 64.227,130.104 64.672,128.483 64.672,126.396 C 64.672,124.308 64.22,122.679 63.315,121.508 C 62.41,120.338 61.16,119.752 59.566,119.752 Z M 58.907,130.775 C 57.829,130.775 57.041,130.459 56.544,129.827 C 56.048,129.195 55.799,128.187 55.799,126.8 L 55.799,126.396 C 55.799,124.84 56.046,123.713 56.538,123.016 C 57.031,122.319 57.836,121.971 58.953,121.971 C 59.893,121.971 60.617,122.356 61.125,123.126 C 61.634,123.896 61.888,124.994 61.888,126.419 C 61.888,127.851 61.636,128.935 61.131,129.671 C 60.627,130.407 59.885,130.775 58.907,130.775 Z" marker-start="none" marker-end="none"/>
        <path d="M 73.406,119.752 C 71.419,119.752 69.865,120.332 68.744,121.491 C 67.624,122.65 67.063,124.247 67.063,126.28 C 67.063,128.368 67.583,130.008 68.623,131.202 C 69.663,132.396 71.092,132.993 72.91,132.993 C 74.597,132.993 75.929,132.481 76.907,131.456 C 77.886,130.432 78.375,129.022 78.375,127.228 L 78.375,125.76 L 69.859,125.76 C 69.898,124.52 70.233,123.567 70.864,122.901 C 71.496,122.234 72.386,121.901 73.533,121.901 C 74.289,121.901 74.991,121.973 75.642,122.115 C 76.293,122.257 76.992,122.494 77.739,122.825 L 77.739,120.619 C 77.076,120.303 76.406,120.08 75.729,119.949 C 75.051,119.818 74.277,119.752 73.406,119.752 Z M 72.91,130.936 C 72.047,130.936 71.355,130.663 70.836,130.116 C 70.316,129.569 70.006,128.772 69.905,127.724 L 75.706,127.724 C 75.69,128.78 75.436,129.579 74.943,130.122 C 74.45,130.665 73.772,130.936 72.91,130.936 Z" marker-start="none" marker-end="none"/>
        <path d="M 92.701,119.983 L 89.974,119.983 L 89.974,127.84 C 89.974,128.826 89.776,129.562 89.379,130.047 C 88.982,130.532 88.353,130.775 87.49,130.775 C 86.342,130.775 85.503,130.436 84.971,129.758 C 84.44,129.08 84.174,127.944 84.174,126.349 L 84.174,119.983 L 81.459,119.983 L 81.459,132.762 L 83.585,132.762 L 83.966,131.086 L 84.105,131.086 C 84.49,131.695 85.037,132.165 85.745,132.496 C 86.454,132.827 87.24,132.993 88.102,132.993 C 91.168,132.993 92.701,131.433 92.701,128.314 L 92.701,119.983 Z" marker-start="none" marker-end="none"/>
       </g>
      </g>
      <g id="Group_2" fill-rule="nonzero" stroke-linejoin="round" stroke-linecap="round" stroke="none" stroke-width="1.012" fill="#000000" stroke-miterlimit="79.8403193612775">
       <g id="Group_3">
        <path d="M 57.965,81.411 L 37.866,81.411 L 37.866,82.914 L 54.8,108.433 L 38.638,108.433 L 38.638,110.332 L 57.51,110.332 L 57.51,108.829 L 40.537,83.31 L 57.965,83.31 L 57.965,81.411 Z" marker-start="none" marker-end="none"/>
        <path d="M 77.609,91.619 L 65.858,91.619 L 61.823,81.411 L 59.587,81.411 L 71.219,110.451 L 72.505,110.451 L 83.9,81.411 L 81.625,81.411 L 77.609,91.619 Z M 66.59,93.438 L 76.936,93.438 L 73,103.864 C 72.67,104.682 72.274,105.816 71.813,107.266 C 71.457,106 71.068,104.853 70.645,103.824 L 66.59,93.438 Z" marker-start="none" marker-end="none"/>
        <path d="M 101.921,91.619 L 90.17,91.619 L 86.135,81.411 L 83.899,81.411 L 95.531,110.451 L 96.817,110.451 L 108.212,81.411 L 105.937,81.411 L 101.921,91.619 Z M 90.902,93.438 L 101.248,93.438 L 97.312,103.864 C 96.982,104.682 96.586,105.816 96.125,107.266 C 95.769,106 95.38,104.853 94.957,103.824 L 90.902,93.438 Z" marker-start="none" marker-end="none"/>
        <path d="M 131.752,81.411 L 129.279,81.411 L 118.182,96.683 L 114.324,93.28 L 114.324,81.411 L 112.306,81.411 L 112.306,110.332 L 114.324,110.332 L 114.324,95.298 L 117.529,98.503 L 128.864,110.332 L 131.435,110.332 L 119.586,98.107 L 131.752,81.411 Z" marker-start="none" marker-end="none"/>
       </g>
      </g>
     </g>
    </g>
   </g>
  </svg>

)

export default OpenzaakLogo
